/* You can add global styles to this file, and also import other style files */
@import "~@ctrl/ngx-emoji-mart/picker";

$leadColor: #00aeee;
$clientColor: #119c99;
$testColor: #d3247e;
$baseColor: #1b3f4e;

* {
  box-sizing: border-box;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
:root {
  --pinkColor: #f5587b;
  --greenColor: #00babf;
  --darkBlueColor: #345461;
  --orangeColor: #ff6f3b;
  --yellowColor: #ef900c;
  --grayColor: gray;
  --lightGrayColor: #f1f1f1;

  --bp-largest: 75em; //1200px
  --bp-medium: 56.25em; //800px
  --bp-small: 37.5em; //600px
  --bp-smallest: 31.25em; //500px
}
html.cdk-global-scrollblock {
  position: relative;
}
html,
body {
  height: 100%;
  font-size: 62.5%;
  box-sizing: border-box;
  position: relative;
  @media only screen and (max-width: 1200px) {
    font-size: 50%;
  }
}
body {
  margin: 0;
  font-family: Segoe UI, "Helvetica Neue", sans-serif !important;
  background-color: #f9f9f9;
  //overflow-y: scroll;
}
*:focus {
  outline: none;
}
textarea:focus,
input:focus {
  outline: none;
}
/*buttons and links*/
.back {
  border: 0;
  background: transparent;
  cursor: pointer;
  margin: 1.5rem 0;
  font-weight: 500;
  font-size: 1.6rem;

  &--lead {
    color: $leadColor;
  }
  &--client {
    color: $clientColor;
  }
  &--test {
    color: $testColor;
  }
  &:hover {
    text-decoration: underline;
  }
}

.btn-cancel-form {
  &--test {
    border: 1px solid $testColor !important;
    color: $testColor;
  }
  &--lead {
    border: 1px solid $leadColor !important;
    color: $leadColor;
  }
  &--client {
    border: 1px solid $clientColor !important;
    color: $clientColor;
  }
}

.rect-action-btn {
  //padding: 1.2rem;
  border: 0;
  color: #fff;
  font-size: 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s;

  &--lead {
    background-color: $leadColor;

    &:hover {
      background-color: darken($leadColor, 10%);
    }
  }
  &--client {
    background-color: $clientColor;

    &:hover {
      background-color: darken($clientColor, 10%);
    }
  }
  &--test {
    background-color: $testColor;

    &:hover {
      background-color: darken($testColor, 10%);
    }
  }
}

.btn-wide {
  margin-top: 1rem;
  width: 100%;
  padding: 1rem 0;
  border: 0;
  color: $leadColor;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s;

  &--lead {
    background-color: $leadColor;
    color: white;

    &:hover {
      background-color: darken($leadColor, 10%);
    }
  }
  &--client {
    background-color: $clientColor;
    color: white;

    &:hover {
      background-color: darken($clientColor, 10%);
    }
  }
  &--test {
    background-color: $testColor;
    color: white;

    &:hover {
      background-color: darken($testColor, 10%);
    }
  }
}

//divider
.divider {
  height: 2px;
  margin: 1rem 0;
  &--lead {
    background-color: $leadColor;
  }
  &--client {
    background-color: $clientColor;
  }
  &--test {
    background-color: $testColor;
  }
}

/*round select*/
.mat-form-field.round-select {
  .mat-select {
    padding: 1rem 0 1rem 1rem;
    border: 1.5px solid #1b3f4e;
    border-radius: 2rem;
    box-sizing: border-box;

    @media screen and (max-width: 1200px) {
      width: 100px;
    }

    &-arrow {
      margin: 0 10px;
    }
  }

  .mat-form-field-label-wrapper {
    font-size: 1.3rem;
    top: -3px;
    left: 15px;
  }
  .mat-form-field-underline {
    visibility: hidden;
    height: 0;
  }
}
.cdk-overlay-pane .mat-select-panel {
  top: -12px;
  position: absolute;
  max-height: 190px !important;
  max-width: fit-content;
  //height: 200px;
}
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  line-height: 2.4em !important;
  height: 2.4em !important;
}
//input
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;

  .edit-field {
    padding: 1.2rem 0.5rem;
    background-color: #f8f8f8;
  }
}
.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: $leadColor !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $leadColor;
}
//errors
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  position: absolute;
  left: 20rem;
  top: 1rem;
}

/*row item*/
.row-container {
  &__item {
    border-bottom: 1px solid #d2eceb;
    min-height: 70px !important;
    height: auto !important;
    display: flex !important;

    &__cc {
      margin-top: 1rem;
      .mat-form-field:not(.mat-compelete) {
        margin-right: 3rem;
        input:not(.input-compelete) {
          border: 1px solid #000;
        }
      }
      .mat-form-field-label-wrapper {
        left: 10px !important;
        top: -5px;
      }
    }

    &--auto {
      height: auto !important;
    }

    .phone-img {
      position: relative;
      left: -10px;
    }

    .boldSpan {
      font-weight: 500;
      margin-right: 3rem;
      width: 12rem;
    }

    &--action {
      margin-top: 1rem;
      div.mat-list-item-content {
        padding: 0 !important;
      }
    }
  }
}

/*Typography*/
.card-title {
  font-size: 2rem;
  font-weight: 500;
}

/*utilities*/

//typography
.text-center {
  text-align: center;
}

.w-70 {
  max-width: 70rem;
}
//margin
.mb-4 {
  margin-bottom: 4rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mr-1 {
  margin-right: 1rem;
}

//padding
.py-1 {
  padding: 1rem 0;
}

.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}

.d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.d-flex-center {
  display: flex;
  align-items: center;
}
.d-flex-center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}
//angular input material hide placeholsder when in focus
.mat-form-field-should-float .mat-form-field-label-wrapper {
  display: none;
}
.mat-form-field.mat-form-field-should-float.upgrade
  .mat-form-field-label-wrapper {
  display: unset !important;
}
app-patient-reports-edit {
  .mat-form-field-should-float .mat-form-field-label-wrapper {
    display: block !important;
  }
}
//angular input number always show arroes
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
.text {
  font-weight: 500;
  &--lead {
    color: $leadColor;
  }
  &--client {
    color: $clientColor;
  }
  &--test {
    color: $testColor;
  }
  &--base {
    color: $baseColor;
    font-weight: 400;
  }
}
.activitiesThumbList {
  ::ng-deep {
    .mat-list-base .mat-list-item {
      height: auto;
    }
  }
}
.logging--btn {
  border-radius: 2rem;
  border: 0;
  background-color: #ff8a5c;
  color: #fff;
  cursor: pointer;
  outline: none;
  margin-left: auto;
  margin-right: 15px;
  height: 30px;
  width: 90px;
  font-size: 14px;
}
.btn-submit {
  border-radius: 2rem;
  margin-top: 1rem;
  width: 100px;
  padding: 1rem 0;
  border: 0;
  color: $leadColor;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s;

  &--lead {
    color: #fff;
    background-color: $leadColor;

    &:hover {
      background-color: darken($leadColor, 10%);
    }
  }
  &--client {
    color: #fff;
    background-color: $clientColor;

    &:hover {
      background-color: darken($clientColor, 10%);
    }
  }
  &--test {
    color: #fff;
    background-color: $testColor;

    &:hover {
      background-color: darken($testColor, 10%);
    }
  }
}
.btn-cancel {
  border-radius: 2rem;
  margin-top: 1rem;
  width: 100px;
  padding: 1rem 0;
  border: 0;
  color: $leadColor;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s;

  &--lead {
    color: $leadColor;
  }
  &--client {
    color: $clientColor;
  }
  &--test {
    color: $testColor;
  }
}
.activities-done-btn {
  border: 1px solid red;
  border-radius: 2rem;
  margin-top: 1rem;
  width: 100px;
  padding: 0.3rem 0;
  font-family: Segoe UI;
  color: red;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s;
}
.round-indicator {
  border: 0;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  font-family: Segoe UI;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.2s;
  //background-color: #00aeee;
  text-align: center;
  white-space: nowrap;
  &.test {
    border: 2px solid $testColor;
    color: $testColor;
  }
  &.testBlue {
    border: 2px solid navy;
    color: navy;
  }
}
.activities-complete-btn {
  border: 0;
  border-radius: 1.5rem;
  margin-top: 1rem;
  width: 120px;
  padding: 0.5rem 0;
  font-family: Segoe UI;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.2s;
  background-color: #00aeee;
  text-align: center;
}
.task-overdue-tab {
}
.task-overdue-tab-unselected {
}
.btn-add-product {
  border-radius: 2rem;
  margin-top: 1rem;
  width: 100px;
  padding: 0.5rem 0;
  border: 0;
  color: #00aeee;
  font-size: 1.8rem;
  cursor: pointer;
  font-size: 15px;
  font-family: Segoe UI;
  transition: all 0.2s;

  &.lead {
    color: #fff;
    background-color: $leadColor;

    &:hover {
      background-color: darken($leadColor, 10%);
    }
  }
}
.form-error {
  margin-left: 20px;
  color: red;
}
.lead-status:hover,
.lead-status.mat-selected {
  color: white !important;
}
.lead-status2:hover ,.lead-status2.mat-selected //New Lead
{
  background-color: #00aeee !important;
}
.lead-status3:hover ,.lead-status3.mat-selected //Renewed Lead
{
  background-color: #35c1f5 !important;
}
.lead-status5:hover ,.lead-status5.mat-selected //Attempted 1
{
  background-color: #ff8a5c !important;
}
.lead-status6:hover ,.lead-status6.mat-selected //Attempted 2
{
  background-color: #ff8158 !important;
}
.lead-status7:hover ,.lead-status7.mat-selected //Attempted 3
{
  background-color: #ff7854 !important;
}
.lead-status8:hover ,.lead-status8.mat-selected //Attempted 4
{
  background-color: #ff6f51 !important;
}
.lead-status9:hover ,.lead-status9.mat-selected //Attempted 5
{
  background-color: #ff664d !important;
}
.lead-status10:hover ,.lead-status10.mat-selected //Attempted 6
{
  background-color: #ff5e49 !important;
}
.lead-status11:hover ,.lead-status11.mat-selected //Attempted 7
{
  background-color: #ff5546 !important;
}
.lead-status13:hover ,.lead-status13.mat-selected //Contant created
{
  background-color: #07a6c8 !important;
}
.lead-status14:hover ,.lead-status14.mat-selected //Relevant
{
  background-color: #119c99 !important;
}
.lead-status18:hover ,.lead-status18.mat-selected //Didn't Answer Several Times
{
  background-color: #e62e60 !important;
}
.lead-status19:hover ,.lead-status19.mat-selected// Wrong Number
{
  background-color: #dc296f !important;
}
.lead-status20:hover ,.lead-status20.mat-selected //Not Interested
{
  background-color: #d3247e !important;
}
.lead-status21:hover ,.lead-status21.mat-selected //Not Interested
{
  background-color: #0b6c32 !important;
}
.lead-status-select {
  font-size: 14px;
  .mat-select-value {
    color: white;
  }
}
.TableClearFix {
  padding-top: 10rem;
}
.logChangedTo {
  color: orange;
}
.sortSelected {
  color: $clientColor;
}
.clickble {
  cursor: pointer;
}
.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.greenArrow {
  border: solid green;
  border-width: 0 2px 2px 0;
}
.breadCrumb {
  cursor: pointer;
  font-size: 14px;
  &.selected {
    color: $clientColor;
  }
}
.btn-rect {
  cursor: pointer;
  background-color: white;
  padding: 10px;
  &--client {
    border: 2px solid $clientColor;
    color: $clientColor;
    border-radius: 5px;
  }
}
.rect-select {
  padding: 1rem;
  border: 1.5px solid #1b3f4e;
  border-radius: 5px;
  box-sizing: content-box;
}
.rotate180 {
  -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg); /* FF */
  -o-transform: rotate(180deg); /* Opera */
  -ms-transform: rotate(180deg); /* IE9 */
  transform: rotate(180deg);
}
.paginationBtn {
  border: 0;
  background-color: #f9f9f9;
  outline: none;
  cursor: pointer;
}

.btn-round {
  border: 0;
  margin-top: 0;
  padding: 0.5rem 3rem;
  min-height: 45px;
  font-size: 1.8rem;
  border-radius: 50px;
  color: #fff;
  position: relative;
  top: -7px;
  margin-right: 20px;
  cursor: pointer;

  &--error {
    background-color: #ff0000;

    &:hover {
      background-color: darken(#ff0000, 10%);
    }
  }
  &--shipping {
    background-color: #d3247e;

    &:hover {
      background-color: darken(#d3247e, 10%);
    }
  }
}
.user-align-right {
  text-align: right !important;
  direction: rtl !important;
}

::ng-deep {
  .cdk-overlay-pane {
    max-height: 500px;
  }
}
.call-income-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}
.mat-tooltip {
  font-size: 12px !important;
}
.error-snackbar-snp {
  max-width: 79% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 1rem !important;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
